<template>
  <div>
    <!-- Import component -->
    <app-bar-top></app-bar-top>
    <app-header-inner></app-header-inner>
    <app-page-Title
      :heading="heading"
      :sousHeading="sousHeading"
    ></app-page-Title>

    <!-- Start Contact -->
    <section class="contact" id="contact">
      <div class="container">
        <i class="flaticon-lotus"></i>
        <div class="row">
          <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
            <div class="contact-boxes">
              <div class="row">
                <div class="col-md-4">
                  <div class="contact-box">
                    <div class="icon-box">
                      <i class="fa fa-phone"></i>
                    </div>
                    <div class="content-box">
                  <a href="https://wa.me/447762162224">
                  WhatsApp
                  </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="contact-box">
                    <div class="icon-box">
                      <i class="fa fa-envelope"></i>
                    </div>
                    <div class="content-box">
                      <a href="mailto:sis@phoenix-Heart.yoga">
                  E-Mail
                  </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="contact-box">
                    <div class="icon-box">
                      <i class="fas fa-map-marker-alt"></i>
                    </div>
                    <div class="content-box">
                      <a href="https://goo.gl/maps/VrGEpC9y2hPhNVfc6">
                  Address
                  </a>
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact -->

    <!-- Import components NewsLetter -->
    <div class="row">
          <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
            <div class="col-md-4"></div>
            <p align="center"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9437.274083158745!2d-2.4840775!3d53.7482112!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xff513bd25e797156!2sPhoenix%20Heart%20Yoga%20with%20Sis!5e0!3m2!1sen!2suk!4v1673825592111!5m2!1sen!2suk"
     width="450" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></p>
    </div>
  </div>
    <app-newsLetter :class="'area'"></app-newsLetter>
    <app-footer> </app-footer>
  </div>
</template>

<script>
import UpperBar from "../../components/upperBar.vue";
import headerInner from "../../components/headerInner";
import pageTitle from "../../components/widget/pageTitle.vue";
import footer from "../../components/footer.vue";
export default {
  data: function () {
    return {
      heading: "Contact US",
      sousHeading: "Contact US",
    };
  },
  components: {
    "app-bar-top": UpperBar,
    "app-header-inner": headerInner,
    "app-page-Title": pageTitle,
    "app-footer": footer,
  },
};
</script>

<style lang="scss" scoped></style>